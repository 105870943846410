<template>
  <div class="write">
    <div class="title">
      <span>新增</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">时间:</span>
          <el-date-picker type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="span">用药日期:</span>
          <el-date-picker type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="span">老人姓名:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">床位号:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item-title">
          <span>添加药品</span>
        </div>
        <div class="item">
          <span class="span">选择药品:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">数量:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">最小规格:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
      </div>
      <el-table :data="tableData" height="200" style="width: 100%">
        <el-table-column prop="date" label="药品名称">
        </el-table-column>
        <el-table-column prop="name" label="数量">
        </el-table-column>
        <el-table-column prop="date" label="规格">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-08',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-06',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      total: 400,
      currentPage: 0
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 160px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 1);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 720px;
    margin-top: 10px;
    padding: 70px 40px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 30px;
      right: 18px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 150px;
        height: 38px;
      }
    }

    .item-title {
      width: 100%;
      margin-bottom: 20px;
      color: #04B4D0;
      font-size: 20px;
    }

    // 表格样式修改
    .el-table {
      background-color: #081C38;
      border: 4px solid #073F73;
      box-sizing: border-box;

      &::before {
        background-color: transparent;
      }

      // 表头首行
      /deep/th.el-table__cell.is-leaf {
        border-bottom: none;
      }

      /deep/td.el-table__cell {
        border-bottom: 1px solid #01EEFD;
      }

      // 表头背景颜色
      /deep/th.el-table__cell {
        background-color: #024276;
      }

      /deep/.el-table__cell {
        text-align: center;
        color: #fff;
      }

      /deep/tr {
        background-color: #081C38;
      }

      /deep/.el-table__body tr:hover>td.el-table__cell {
        background-color: transparent !important;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }

      /deep/.is-indeterminate .el-checkbox__inner,
      /deep/.is-checked .el-checkbox__inner {
        background-color: #04B4D0;
      }

      /deep/.el-table__body-wrapper::-webkit-scrollbar {
        width: 0;
      }

      /deep/.el-table__empty-text {
        color: #fff;
      }

      .flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .item {
          font-size: 14px;
          cursor: pointer;
        }

        .green {
          color: #17AF12;
        }

        .red {
          color: #FF6600;
        }

        .organ {
          color: #FF0000;
        }

        .icon {
          color: #0B75EF;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    // 页码样式修改
    .el-pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      /deep/.el-pagination__total,
      /deep/.el-pagination__jump {
        color: #01EEFD;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      /deep/.el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 26px;
      margin: 20px auto 0;

      .el-button {
        width: 70px;
        height: 26px;
        line-height: 26px;
        padding: 0;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
